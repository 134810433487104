<template lang="pug">
.customer-data.card
  .card-header
    | {{ $t('title') }}
    .fa-pull-right
      .btn-group.btn-group-sm
        button.btn.btn-primary(
          v-if           = '!editing'
          @click.prevent = 'toggleEditing'
        ) {{ $t("common:edit") }}
        button.btn.btn-outline-primary(
          v-if         = 'isPrivateCustomer'
          v-fi-confirm = '{ ok: resetPassword, message: $t("resetPasswordConfirm") }'
        ) {{ $t("resetPassword") }}
          i.fa.fa-spinner.fa-pulse.fa-lg(v-if = '$vueLoading.isLoading("reset:request")')
        button.btn.btn-outline-primary(v-fi-confirm = '{ ok: anonymizeCustomer, message: $t("anonymizationConfirm") }') {{ $t("dataAnonymization") }}
          i.fa.fa-spinner.fa-pulse.fa-lg(v-if = '$vueLoading.isLoading("customer:anonymize:save")')
        button.btn.btn-outline-danger(
          v-if         = 'canDeleteCustomer'
          :title       = '$t("deleteCustomer")'
          v-fi-confirm = '{ ok: doCustomerDelete, message: $t("deleteCustomerConfirm") }'
          v-b-tooltip.hover
        )
          i.fa.fa-spinner.fa-pulse.fa-lg(v-if = '$vueLoading.isLoading("customer:delete")')
          i.far.fa-trash-alt
  .card-body
    form.form-horizontal(
      novalidate
      @submit.prevent = 'onSubmit'
    )
      template(v-if = 'editing')
        fi-form-field(:label = '$t("status")')
          fi-switch(
            v-model   = 'customerData.enabled'
            :on-text  = '$t("common:enabled")'
            :off-text = '$t("common:disabled")'
          )
        fi-form-field(:label = '$t("scoringAcceptance")')
          fi-switch(
            v-model   = 'customerData.isEnabledAutomaticScoringAcceptance'
            :on-text  = '$t("common:enabled")'
            :off-text = '$t("common:disabled")'
          )
        fi-form-field(:label = '$t("identityAuthenticated")')
          fi-switch(
            v-model   = 'customerData.identityAuthenticated'
            :on-text  = '$t("common:enabled")'
            :off-text = '$t("common:disabled")'
          )
        fi-form-field(:label = '$t("acceptAdvertising")')
          fi-switch(
            v-model   = 'customerData.acceptAdvertising'
            :on-text  = '$t("common:enabled")'
            :off-text = '$t("common:disabled")'
          )
        fi-form-field(:label = '$t("acceptPep")')
          fi-switch(
            v-model   = 'customerData.acceptPep'
            :on-text  = '$t("common:enabled")'
            :off-text = '$t("common:disabled")'
          )
        fi-form-field(:label = '$t("sanctions")')
          fi-switch(
            v-model   = 'customerData.existInSanctions'
            :on-text  = '$t("common:enabled")'
            :off-text = '$t("common:disabled")'
          )
        fi-form-field(:label = '$t("blacklisted")')
          fi-switch(
            v-model   = 'customerData.isInBlacklist'
            :on-text  = '$t("common:enabled")'
            :off-text = '$t("common:disabled")'
          )
        fi-form-field(:label = '$t("applicationAcceptance")')
          fi-switch(
            v-model   = 'customerData.applicationAcceptance'
            :on-text  = '$t("common:enabled")'
            :off-text = '$t("common:disabled")'
          )
        fi-form-field(:label = '$t("acceptTerms")')
          fi-switch(
            v-model   = 'customerData.acceptTerms'
            :on-text  = '$t("common:enabled")'
            :off-text = '$t("common:disabled")'
          )
        fi-form-field(v-if = 'customerData.acceptTerms' :label = '$t("acceptTermsText")')
          input.form-control.form-control-sm.animated.fadeIn(
            v-model.trim = 'customerData.acceptTermsText'
            type         = 'text'
          )
        fi-form-field(:label = '$t("name")')
          .form-row(v-if = 'isPrivateCustomer')
            .col: input.form-control.form-control-sm.animated.fadeIn(
              v-model.trim = 'customerData.firstName'
              :placeholder = '$t("firstName")'
              type         = 'text'
            )
            .col: input.form-control.form-control-sm.animated.fadeIn(
              v-model.trim = 'customerData.lastName'
              :placeholder = '$t("lastName")'
              type         = 'text'
            )
          input.form-control.form-control-sm.animated.fadeIn(
            v-else
            v-model.trim = 'customerData.customerName'
            type         = 'text'
          )
        fi-form-field(:label = '$t("idCode")')
          input.form-control.form-control-sm.animated.fadeIn(
            v-model.trim = 'customerData.idCode'
            type         = 'text'
          )

        fi-form-field(
          :label     = '$t("depositTaxAccountNumber")'
          :state     = '!$v.customerData.depositTaxAccountNumber.$error'
        )
          template(#error)
            .error-message(v-if = '!$v.customerData.depositTaxAccountNumber.maxLength') {{ $t('depositTaxAccountNumberLengthError', { from: 19, to: 30 }) }}
            .error-message(v-if = '!$v.customerData.depositTaxAccountNumber.minLength') {{ $t('depositTaxAccountNumberLengthError', { from: 19, to: 30 }) }}
          input.form-control.form-control-sm.animated.fadeIn(
            v-model.trim = 'customerData.depositTaxAccountNumber'
            type         = 'text'
            :class       = '{ "is-invalid": $v.customerData.depositTaxAccountNumber.$error }'
          )

        fi-form-field(v-if   = '!isCorporateCustomer(customer)'
                      :label = '$t("login")')
          input.form-control.form-control-sm.animated.fadeIn(
            v-model.trim = 'customerData.username'
            type         = 'text'
          )
        fi-form-field(:label = '$t("sector")')
          fi-select.animated.fadeIn(
            v-model.number = 'customerData.sectorId'
            :options       = 'optionsFromClassifier("sectors")'
            required
            sm
          )
        fi-form-field(:label = '$t("segment")')
          fi-select.animated.fadeIn(
            v-model.number = 'customerData.segmentId'
            :options       = 'optionsFromClassifier("segments")'
            required
            sm
          )
        //- fi-form-field(:label = '$t("employmentType")')
        //-   fi-select.animated.fadeIn(
        //-     v-model.number = 'customerData.employmentTypeId'
        //-     :options       = 'optionsFromClassifier("employmentType")'
        //-     required
        //-     sm
        //-   )
        fi-form-field(:label = '$t("aml")')
          fi-select.animated.fadeIn(
            v-model.number = 'customerData.antiMoneyLaunderingCategoryId'
            :options       = 'optionsFromClassifier("antiMoneyLaunderingCategory")'
            required
            sm
          )
        fi-form-field(:label = '$t("riskCategory")')
          fi-select.animated.fadeIn(
            v-model.number = 'customerData.riskCategoryId'
            :options       = 'optionsFromClassifier("riskCategory")'
            required
            sm
          )
        fi-form-field(:label = 'isPrivateCustomer ? $t("birthday") : $t("registered")')
          fi-datepicker.animated.fadeIn(
            v-model = 'customerData.dateOfEstablish'
            sm
          )
        fi-form-field(:label = '$t("manager")')
          fi-select.animated.fadeIn(
            v-model.number = 'customerData.customerManagerId'
            :options       = 'optionsFromManagers'
            sm
            required
          )
        template(v-if = 'isLimitEnabled')
          fi-form-field(:label = '$t("currentLimit")')
            input.form-control.form-control-sm.animated.fadeIn(
              v-model.trim = 'customerData.creditLimit'
              type         = 'text'
            )
          fi-form-field(:label = '$t("availableLimit")')
            input.form-control.form-control-sm.animated.fadeIn(
              :value       = 'customer.creditLimitAvailable'
              type         = 'text'
              readonly
            )
        fi-form-field(
          :label = '$t("phone")'
          :state = '!$v.customerData.phoneNumbers.$error'
        )
          template(#error)
            .error-message(v-if = '!$v.customerData.phoneNumbers.required') {{ $t('phoneNumbersRequired') }}
          customer-contact-list(
            v-model    = '$v.customerData.phoneNumbers.$model'
            :add-label = '$t("add")'
            name       = 'phoneContact'
            allow-main
          )
            template(#input="{ contact, onInput }")
              fi-phone-input.form-control-sm.form-control.animated.fadeIn(
                :value     = 'contact'
                :countries = 'countries'
                :class     = '{ "is-invalid": $v.customerData.phoneNumbers.$error }'
                @input     = 'onInput'
              )
        fi-form-field(:label = '$t("deskPhone")')
          customer-contact-list(
            v-model    = 'customerData.deskPhoneNumbers'
            :add-label = '$t("add")'
            name       = 'phoneContact'
          )
            template(#input="{ contact, onInput }")
              fi-phone-input.form-control-sm.form-control.animated.fadeIn(
                :value     = 'contact'
                :countries = 'countries'
                @input     = 'onInput'
              )
        fi-form-field(
          :label = '$t("email")'
          :state = '!$v.customerData.emails.$error'
        )
          template(#error)
            .error-message(v-if = '!$v.customerData.emails.required') {{ $t('emailRequired') }}
          customer-contact-list(
            v-model    = 'customerData.emails'
            :add-label = '$t("add")'
            name       = 'emailContact'
            :inputClass = '{ "is-invalid": $v.customerData.emails.$error }'
            allow-main
          )
        fi-form-field(:label = '$t("language")')
          fi-select.animated.fadeIn(
            v-model.number = 'customerData.languageId'
            :options       = 'languages'
            required
            sm
          )
        fi-form-field(:label = '$t("country")')
          fi-select.animated.fadeIn(
            v-model.number = 'customerData.countryId'
            :options       = 'optionsFromClassifier("countries")'
            sm
          )
        fi-form-field(:label = '$t("residence")')
          .form-row
            .col
              fi-select.animated.fadeIn(
                v-model.number = 'customerData.residenceCountryId'
                :options       = 'optionsFromClassifier("countries")'
                required
                sm
              )
            .col
              fi-select.animated.fadeIn(
                v-model.number = 'customerData.residenceTypeId'
                :options       = 'optionsFromClassifier("residenceType")'
                required
                sm
              )
        fi-form-field(:label = '$t("accountExternal")')
          .input-group.input-group-sm
            input.form-control.form-control-sm(v-model = 'accountExternalId')
            .input-group-append
              button.btn.btn-sm.btn-primary(
                :disabled      = '!accountExternalId'
                :title         = '$t("add")'
                @click.prevent = 'addAccount'
              )
                i.far.fa-plus-square.fa-lg
          c-list-group
            c-list-group-item.p-1(
              v-for = '(account, index) in customerData.externalBankAccounts'
              :key  = 'index'
            )
              .col.d-flex.align-items-center.justify-content-between
                label.mb-0 {{ account.accountExternalNumber }}
                  b-radio.fa-pull-left(
                    type    = 'radio'
                    v-model = 'account.generalAccount'
                    @change = 'setDefaultAccount(index)'
                    name    = 'defaultAccount'
                    :value  = 'true'
                  )
                button.btn.btn-sm.btn-link.text-danger.fa-pull-right(@click.prevent = 'removeAccount(index)')
                  i.far.fa-trash-alt.fa-lg
      template(v-else)
        fi-data-field(:label = '$t("status")')
          fi-switch(
            v-model   = 'customer.enabled'
            :on-text  = '$t("common:enabled")'
            :off-text = '$t("common:disabled")'
            read-only
          )
        fi-data-field(:label = '$t("scoringAcceptance")')
          fi-switch(
            v-model   = 'customer.isEnabledAutomaticScoringAcceptance'
            :on-text  = '$t("common:enabled")'
            :off-text = '$t("common:disabled")'
            read-only
          )
        fi-data-field(:label = '$t("identityAuthenticated")')
          fi-switch(
            v-model   = 'customer.identityAuthenticated'
            :on-text  = '$t("common:enabled")'
            :off-text = '$t("common:disabled")'
            read-only
          )
        fi-data-field(:label = '$t("acceptAdvertising")')
          fi-switch(
            v-model   = 'customer.acceptAdvertising'
            :on-text  = '$t("common:enabled")'
            :off-text = '$t("common:disabled")'
            read-only
          )
        fi-data-field(:label = '$t("acceptPep")')
          fi-switch(
            v-model   = 'customer.acceptPep'
            :on-text  = '$t("common:enabled")'
            :off-text = '$t("common:disabled")'
            read-only
          )
        fi-data-field(:label = '$t("sanctions")')
          fi-switch(
            v-model   = 'customer.existInSanctions'
            :on-text  = '$t("common:enabled")'
            :off-text = '$t("common:disabled")'
            read-only
          )
        fi-data-field(:label = '$t("blacklisted")')
          fi-switch(
            v-model   = 'customer.isInBlacklist'
            :on-text  = '$t("common:enabled")'
            :off-text = '$t("common:disabled")'
            read-only
          )
        fi-data-field(:label = '$t("applicationAcceptance")')
          fi-switch(
            v-model   = 'customer.applicationAcceptance'
            :on-text  = '$t("common:enabled")'
            :off-text = '$t("common:disabled")'
            read-only
          )
        fi-data-field(:label = '$t("acceptTerms")')
          fi-switch(
            v-model   = 'customer.acceptTerms'
            :on-text  = '$t("common:enabled")'
            :off-text = '$t("common:disabled")'
            read-only
          )
        fi-data-field(v-if = 'customer.acceptTerms' :label = '$t("acceptTermsText")') {{ customer.acceptTermsText }}
        fi-data-field(:label = '$t("name")') {{ name }}
        fi-data-field(:label = '$t("idCode")') {{ customer.idCode }}
        fi-data-field(:label = '$t("depositTaxAccountNumber")') {{ customer.depositTaxAccountNumber }}
        fi-data-field(v-if   = '!isCorporateCustomer(customer)'
                      :label = '$t("login")') {{ customer.username }}
        fi-data-field(:label = '$t("sector")') {{ classifierById('sectors', customer.sectorId).human }}
        fi-data-field(:label = '$t("segment")') {{ classifierById('segments', customer.segmentId).human }}
        //- fi-data-field(:label = '$t("employmentType")') {{ employmentType.human }}
        fi-data-field(:label = '$t("aml")') {{ antiMoneyLaunderingCategory.human }}
        fi-data-field(:label = '$t("riskCategory")') {{ riskCategory.human }}
        fi-data-field(:label = 'isPrivateCustomer ? $t("birthday") : $t("registered")') {{ $t('common:formatDate', { value: customer.dateOfEstablish }) }}
        fi-data-field(:label = '$t("manager")') {{ customerName(customer.manager) }}
        template(v-if = 'isLimitEnabled')
          fi-data-field(:label = '$t("currentLimit")') {{ customer.creditLimit }}
          fi-data-field(:label = '$t("availableLimit")') {{ customer.creditLimitAvailable }}
        fi-data-field(:label = '$t("phone")')
          c-list-group
            c-list-group-item(
              v-for = '(phoneNumber) in customer.phoneNumbers'
              :key  = 'phoneNumber.id'
            )
              .col
                b-radio.fa-pull-left(
                  :checked = 'phoneNumber.main'
                  name     = 'defaultPhone'
                  value    = 'true'
                  disabled
                ) {{ phoneNumber.value }}
        fi-data-field(:label = '$t("deskPhone")')
          c-list-group
            c-list-group-item(
              v-for = '(phoneNumber) in customer.deskPhoneNumbers'
              :key  = 'phoneNumber.id'
            )
              .col {{ phoneNumber.value }}
        fi-data-field(:label = '$t("email")')
          c-list-group
            c-list-group-item(
              v-for = '(contact) in customer.emails'
              :key  = 'contact.id'
            )
              .col
                b-radio.fa-pull-left(
                  :checked = 'contact.main'
                  name     = 'defaultContact'
                  value    = 'true'
                  disabled
                ) {{ contact.value }}
        fi-data-field(:label = '$t("language")') {{ classifierById('languages', customer.languageId).human }}
        fi-data-field(:label = '$t("country")') {{ classifierById('countries', customer.countryId).human }}
        fi-data-field(v-if = 'customer.residenceCountryId || customer.residenceTypeId'
                      :label = '$t("residence")')
          .row
            .col {{ classifierById('countries', customer.residenceCountryId).human }}
            .col {{ classifierById('residenceType', customer.residenceTypeId).human }}
        fi-data-field(:label = '$t("accountExternal")')
          c-list-group
            c-list-group-item(
              v-for = '(account, index) in customer.externalBankAccounts'
              :key  = 'index'
            )
              .col
                b-radio.fa-pull-left(
                  :checked = 'account.generalAccount'
                  name     = 'defaultAccount'
                  value    = 'true'
                  disabled
                ) {{ account.accountExternalNumber }}

      template(v-if = 'editing')
        button.btn.btn-primary(
          :disabled = 'saving'
          type      = 'submit'
        )
          |{{ $t('common:save') }}
          i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'saving')
        | &nbsp;
        button.btn.btn-secondary(
          :disabled      = 'saving'
          @click.prevent = 'toggleEditing'
        ) {{ $t('common:cancel') }}
</template>


<script>
import FiDataField from '@/components/FiDataField'
import FiFormField from '@/components/FiFormField'
import FiSelect from '@/components/FiSelect'
import CustomerDataFields from '@/views/customers/CustomerDataFields'
import pick from 'lodash/pick'
import cloneDeep from 'lodash/cloneDeep'
import { mapActions, mapGetters, mapState } from 'vuex'
import FiDatepicker from '@/components/FiDatepicker'
import FiSwitch from '@/components/FiSwitch'
import FiPhoneInput from '@/components/FiPhoneInput'
import { CButton, CListGroup, CListGroupItem } from '@coreui/vue'
import CustomerContactList from '@/views/customers/CustomerContactList'
import { validation, validators } from '@/validation'
import FiConfirmModal from '@/components/FiConfirmModal'

export default {
  name: 'customer-data',

  components: {
    FiConfirmModal,
    CustomerContactList,
    FiSwitch,
    FiSelect,
    FiDataField,
    FiFormField,
    CustomerDataFields,
    FiDatepicker,
    FiPhoneInput,
    CListGroup,
    CListGroupItem,
    CButton
  },

  mixins: [validation],

  i18nOptions: {},

  data () {
    return {
      accountExternalId: null,
      email: null,
      phone: null,
      deskPhone: null,
      editing: false,
      customerData: {
        applicationAcceptance: false,
        acceptTerms: false,
        acceptTermsText: '',
        antiMoneyLaunderingCategoryId: '',
        identityAuthenticated: false,
        enabled: null,
        isEnabledAutomaticScoringAcceptance: false,
        acceptAdvertising: false,
        acceptPep: false,
        existInSanctions: false,
        isInBlacklist: false,
        customerName: null,
        firstName: null,
        lastName: null,
        idCode: null,
        username: null,
        sectorId: '',
        segmentId: '',
        dateOfEstablish: null,
        customerManagerId: '',
        phoneNumbers: [],
        deskPhoneNumbers: [],
        emails: [],
        languageId: '',
        countryId: '',
        employmentTypeId: '',
        externalBankAccounts: [],
        creditLimit: '',
        riskCategoryId: '',
        residenceCountryId: '',
        residenceTypeId: '',
        depositTaxAccountNumber: ''
      }
    }
  },

  computed: {
    ...mapState('customers', ['customer']),
    ...mapGetters('classifiers', ['classifierById', 'classifierByName', 'managerById', 'optionsFromClassifier', 'optionsFromManagers', 'classifierList']),
    ...mapGetters('customers', ['customerName', 'isCorporateCustomer']),
    ...mapGetters('settings', ['isLimitEnabled', 'settingByKey']),
    ...mapState('auth', ['permissions']),
    canDeleteCustomer () {
      return this.permissions.includes('GENERAL_ADMIN')
    },
    countries () {
      return Object.values(this.classifierList('countries')).map(({ name }) => name)
    },
    currentCustomer () {
      return this.editing ? this.customerData : this.customer
    },
    isPrivateCustomer () {
      return !this.isCorporateCustomer(this.currentCustomer)
    },
    name () {
      return this.customerName(this.currentCustomer)
    },
    saving () {
      return this.$vueLoading.isLoading('customer:save')
    },
    cleanCustomerData () {
      if (this.customerData.phoneNumbers) {
        this.customerData.phoneNumbers.forEach((el, index) => {
          delete el.updatedBy
          delete el.updatedAt
          delete el.createdBy
          delete el.createdAt
        })
      }
      if (this.customerData.deskPhoneNumbers) {
        this.customerData.deskPhoneNumbers.forEach((el, index) => {
          delete el.updatedBy
          delete el.updatedAt
          delete el.createdBy
          delete el.createdAt
        })
      }
      if (this.customerData.emails) {
        this.customerData.emails.forEach((el, index) => {
          delete el.updatedBy
          delete el.updatedAt
          delete el.createdBy
          delete el.createdAt
        })
      }
      let nameFields = {}
      if (this.isPrivateCustomer) {
        nameFields = { customerName: null }
      } else {
        nameFields = { firstName: null, lastName: null }
      }
      if (!this.customerData.depositTaxAccountNumber?.trim()) {
        delete this.customerData.depositTaxAccountNumber
      }
      return {
        ...this.customerData,
        ...nameFields
      }
    },
    antiMoneyLaunderingCategory () {
      const { antiMoneyLaunderingCategory = {} } = this.customer
      return this.classifierByName(antiMoneyLaunderingCategory?.classifier, antiMoneyLaunderingCategory?.code) ?? {}
    },
    employmentType () {
      const { employmentType = {} } = this.customer
      return this.classifierByName(employmentType?.classifier, employmentType?.code) ?? {}
    },
    riskCategory () {
      const { riskCategory = {} } = this.customer
      return this.classifierByName(riskCategory?.classifier, riskCategory?.code) ?? {}
    },
    languages () {
      return Object.values(this.classifierList('languages'))
        .filter(lang => this.settingByKey('supportedBoLanguages').includes(lang.code))
        .map(({ human, id }) => ({ text: human, value: id }))
    }
  },

  validations () {
    return {
      customerData: {
        applicationAcceptance: {},
        acceptTerms: {},
        acceptTermsText: {},
        antiMoneyLaunderingCategoryId: {},
        identityAuthenticated: {},
        enabled: {},
        isEnabledAutomaticScoringAcceptance: {},
        acceptAdvertising: {},
        acceptPep: {},
        isInBlacklist: {},
        existInSanctions: {},
        customerName: {},
        firstName: {},
        lastName: {},
        idCode: {},
        login: {},
        sectorId: {},
        segmentId: {},
        dateOfEstablish: {},
        customerManagerId: {},
        phoneNumbers: {
          required: validators.required,
          each: {}
        },
        deskPhoneNumbers: {},
        emails: {
          required: validators.required,
          each: {}
        },
        languageId: {},
        countryId: {},
        employmentTypeId: {},
        externalBankAccounts: {},
        creditLimit: {},
        riskCategoryId: {},
        depositTaxAccountNumber: {
          minLength: validators.minLength(19),
          maxLength: validators.maxLength(30)
        }
      }
    }
  },

  methods: {
    ...mapActions('customers', ['updateCustomer', 'deleteCustomer', 'anonymizeCustomerData', 'saveDeleteBankAccount']),
    ...mapActions('classifiers', ['loadManagers']),
    ...mapActions('passwordReset', ['sendResetRequest']),
    toggleEditing () {
      if (!this.editing) {
        this.resetData()
        this.loadManagers()
      }
      this.editing = !this.editing
    },
    resetData () {
      this.customerData = {
        ...cloneDeep(pick(this.customer, Object.keys(this.customerData))),
        externalBankAccounts: this.customer.externalBankAccounts?.map(
          (externalAccount) => ({ ...externalAccount })
        ) || [],
        customerManagerId: this.customer.manager?.id,
        employmentTypeId: this.employmentType.id,
        antiMoneyLaunderingCategoryId: this.antiMoneyLaunderingCategory.id,
        riskCategoryId: this.riskCategory.id
      }
      if (this.customer.privateCustomer?.username) {
        this.customerData.username = this.customer.privateCustomer?.username
      }
    },
    async onSubmit () {
      if (this.validate()) {
        await this.updateCustomer({ id: this.customer.id, customerData: this.cleanCustomerData })
        this.toggleEditing()
      }
    },
    async resetPassword ({ done }) {
      await this.sendResetRequest(this.customer.username)
      done()
    },
    async doCustomerDelete ({ done }) {
      await this.deleteCustomer({ customerId: this.customer.id })
      done()
      this.$router.push({ name: 'Customers' })
    },
    async anonymizeCustomer ({ done }) {
      await this.anonymizeCustomerData({ customerId: this.customer.id })
      done()
    },
    addAccount () {
      if (!this.accountExternalId) {
        return
      }
      this.customerData.externalBankAccounts.push({
        accountExternalNumber: this.accountExternalId,
        generalAccount: !this.customerData.externalBankAccounts.length
      })
      this.accountExternalId = null
    },
    async removeAccount (index) {
      const { id } = this.customerData.externalBankAccounts[index]
      if (id) {
        await this.saveDeleteBankAccount({ customerId: this.customer.id, accountId: id })
        this.customerData.externalBankAccounts = this.customer.externalBankAccounts
      } else {
        this.customerData.externalBankAccounts.splice(index, 1)
        if (this.customerData.externalBankAccounts.length) {
          this.customerData.externalBankAccounts[0].generalAccount = true
        }
      }
    },
    setDefaultAccount (index) {
      this.customerData.externalBankAccounts = this.customerData.externalBankAccounts.map((account, idx) => ({
        ...account,
        generalAccount: idx === index
      }))
    }
  }
}
</script>


<style lang='scss' scoped>
.switch-status-wrap {
  padding-top: calc(0.5rem - 1px * 2);
  padding-bottom: calc(0.5rem - 1px * 2);
}
label.switch { margin-bottom: 0; }
</style>


<i18n>
en:
  title:                 "Customer data"
  resetPassword:         "Reset password"
  resetPasswordConfirm:  "Are you sure to send reset password token to customer?"
  dataAnonymization:     "Data anonymization"
  anonymizationConfirm:  "Are you sure to anonymize customer data?"
  status:                "Status"
  scoringAcceptance:     "Automatic scoring acceptance"
  identityAuthenticated: "Customer identity authenticated"
  acceptAdvertising:     "Accept advertising"
  acceptPep:             "Accept PEP"
  sanctions:             "Sanctions"
  blacklisted:           "Blacklisted"
  applicationAcceptance: "Application acceptance"
  name:                  "Name"
  firstName:             "First name"
  lastName:              "Last name"
  idCode:                "ID Code"
  login:                 "Login"
  sector:                "Sector"
  segment:               "Segment"
  employmentType:        "Employment type"
  aml:                   "AML category"
  riskCategory:          "Risk category"
  birthday:              "Birthday"
  registered:            "Established"
  manager:               "Customer manager"
  currentLimit:          "Customer limit"
  availableLimit:        "Customer available limit"
  phone:                 "Mobile phone"
  deskPhone:             "Desk phone"
  email:                 "E-mail"
  language:              "Language"
  country:               "Country of origin"
  accountExternal:       "External account"
  deleteCustomer:        "Delete customer data"
  deleteCustomerConfirm: "Are you sure to delete customer data?"
  add:                   "Add"
  phoneNumbersRequired:  "Phone number cannot be empty"
  emailRequired:         "Email cannot be empty"
  residence:             "Residence"
  acceptTerms:           "Accept terms"
  acceptTermsText:       "Accept terms text"
  depositTaxAccountNumber: "Deposit Tax Account Number"
  depositTaxAccountNumberLengthError: "Deposit Tax Account Number length must be {{ from }} - {{ to }} characters"
et:
  title:                 "Customer data"
  resetPassword:         "Reset password"
  resetPasswordConfirm:  "Are you sure to send reset password token to customer?"
  dataAnonymization:     "Data anonymization"
  anonymizationConfirm:  "Are you sure to anonymize customer data?"
  status:                "Status"
  scoringAcceptance:     "Automatic scoring acceptance"
  identityAuthenticated: "Customer identity authenticated"
  acceptAdvertising:     "Accept advertising"
  acceptPep:             "Accept PEP"
  sanctions:             "Sanctions"
  blacklisted:           "Blacklisted"
  applicationAcceptance: "Application acceptance"
  name:                  "Name"
  firstName:             "First name"
  lastName:              "Last name"
  idCode:                "ID Code"
  login:                 "Login"
  sector:                "Sector"
  segment:               "Segment"
  employmentType:        "Employment type"
  aml:                   "AML category"
  riskCategory:          "Risk category"
  birthday:              "Birthday"
  registered:            "Established"
  manager:               "Customer manager"
  currentLimit:          "Customer limit"
  availableLimit:        "Customer available limit"
  phone:                 "Mobile phone"
  deskPhone:             "Desk phone"
  email:                 "E-mail"
  language:              "Language"
  country:               "Country of origin"
  accountExternal:       "External account"
  deleteCustomer:        "Delete customer data"
  deleteCustomerConfirm: "Are you sure to delete customer data?"
  add:                   "Add"
  phoneNumbersRequired:  "Phone number cannot be empty"
  maritalStatus:         "Marital status"
  residence:             "Residence"
  acceptTerms:           "Accept terms"
  acceptTermsText:       "Accept terms text"
  depositTaxAccountNumber: "Deposit Tax Account Number"
  depositTaxAccountNumberLengthError: "Deposit Tax Account Number length must be {{ from }} - {{ to }} characters"
ru:
  title:                 "Customer data"
  resetPassword:         "Reset password"
  resetPasswordConfirm:  "Are you sure to send reset password token to customer?"
  dataAnonymization:     "Data anonymization"
  anonymizationConfirm:  "Are you sure to anonymize customer data?"
  status:                "Status"
  scoringAcceptance:     "Automatic scoring acceptance"
  identityAuthenticated: "Customer identity authenticated"
  acceptAdvertising:     "Accept advertising"
  acceptPep:             "Accept PEP"
  sanctions:             "Sanctions"
  blacklisted:           "Blacklisted"
  applicationAcceptance: "Application acceptance"
  name:                  "Name"
  firstName:             "First name"
  lastName:              "Last name"
  idCode:                "ID Code"
  login:                 "Login"
  sector:                "Sector"
  segment:               "Segment"
  employmentType:        "Employment type"
  aml:                   "AML category"
  riskCategory:          "Risk category"
  birthday:              "Birthday"
  registered:            "Established"
  manager:               "Customer manager"
  currentLimit:          "Customer limit"
  availableLimit:        "Customer available limit"
  phone:                 "Mobile phone"
  deskPhone:             "Desk phone"
  email:                 "E-mail"
  language:              "Language"
  country:               "Country of origin"
  accountExternal:       "External account"
  deleteCustomer:        "Delete customer data"
  deleteCustomerConfirm: "Are you sure to delete customer data?"
  add:                   "Add"
  phoneNumbersRequired:  "Phone number cannot be empty"
  maritalStatus:         "Marital status"
  residence:             "Residence"
  acceptTerms:           "Accept terms"
  acceptTermsText:       "Accept terms text"
  depositTaxAccountNumber: "Deposit Tax Account Number"
  depositTaxAccountNumberLengthError: "Deposit Tax Account Number length must be {{ from }} - {{ to }} characters"
</i18n>
