<template lang="pug">
.customer-overview.animated.fadeIn
  customer-note-list(:comments = 'customerNotes')
  .card-deck.mb-4
    .card
      .card-header {{ $t('customerData') }}
      .card-body
        customer-data-fields(
          :customer = 'customer'
          :loan-contracts = 'loanContracts'
          :factoring-contracts = 'factoringContracts'
          :deposit-contracts = 'depositContracts'
        )

    customer-communication(
      customer-state       = 'customer'
      messages-state       = 'customerMessages'
      messages-pages-state = 'customerMessagesPages'
      short
    )

  .card-deck.mb-4
    customer-relation-list
    customer-overview-payment

  application-paid-taxes-list(
     v-if     = 'isCorporateCustomer(this.customer)'
    :paid-taxes       = 'applicationPaidTaxes'
  )

  customer-contract-list(
    v-if       = 'loanContracts.length'
    :contracts = 'loanContracts'
    :title     = '$t("activeLoans")'
    loader     = 'customer:loans:fetch'
    full
    summary
  )
    template(#contractItem="{ contract }")
      customer-loan-list-item(
        :key  = 'contract.loanId'
        :loan = 'contract'
        full
      )

  customer-contract-list(
    v-if       = 'factoringContracts.length'
    :contracts = 'factoringContracts'
    :title     = '$t("activeFactoring")'
    loader     = 'customer:factoring:fetch'
    summary
  )
    template(#contractItem="{ contract }")
      customer-factoring-list-item(
        :key       = 'contract.factoringId'
        :factoring = 'contract'
      )

  customer-contract-list(
    v-if       = 'depositContracts.length'
    :contracts = 'depositContracts'
    :title     = '$t("activeDeposits")'
    loader     = 'customer:deposits:fetch'
    full
    summary
  )
    template(#contractItem="{ contract }")
      customer-deposit-list-item(
        :key  = 'contract.id'
        :deposit = 'contract'
      )

  customer-factoring-third-party-list(
    v-if           = 'isFactoringEnabled'
    :third-parties = 'customerThirdParties'
    :customer      = 'customer'
  )
  application-corporate-annual-report-list(
     v-if     = 'isCorporateCustomer(this.customer)'
    :reports = 'annualReports'
    @update  = 'updateAnnualReport'
  )
  customer-history(
    store-module  = 'customers',
    history-state = 'customerHistory'
  )
</template>


<script>
import { loanStatuses } from '@/const'
import CustomerContractList from '@/views/customers/CustomerContractList'
import { mapActions, mapState, mapGetters } from 'vuex'
import CustomerRelatedLoansList from '@/views/customers/CustomerRelatedLoansList'
import CustomerNoteList from './CustomerNoteList'
import CustomerDataFields from './CustomerDataFields'
import CustomerCommunication from './CustomerCommunication'
import CustomerRelationList from './CustomerRelationList'
import CustomerHistory from './CustomerHistory'
import CustomerOverviewPayment from './CustomerOverviewPayment'
import { isDepositEnabled, isFactoringEnabled } from '@/mixins'
import CustomerFactoringThirdPartyList from '@/views/customers/CustomerFactoringThirdPartyList'
import ApplicationPaidTaxesList from '@/views/applications/ApplicationFactoring/ApplicationPaidTaxesList'
import ApplicationCorporateAnnualReportList from '@/views/applications/ApplicationCorporateAnnualReportList'
import { CApplicationCorporateAnnualReport } from '@/models/application/CApplicationCorporateAnnualReport.ts'
import { plainToClass } from 'class-transformer'

const CustomerLoanListItem = () => import('@/views/customers/CustomerLoanListItem')
const CustomerFactoringListItem = () => import('@/views/customers/CustomerFactoringListItem')
const CustomerDepositListItem = () => import('@/views/customers/CustomerDepositListItem')

export default {
  name: 'customer-overview',

  components: {
    CustomerDepositListItem,
    CustomerFactoringListItem,
    CustomerLoanListItem,
    CustomerFactoringThirdPartyList,
    CustomerContractList,
    CustomerRelatedLoansList,
    CustomerOverviewPayment,
    CustomerNoteList,
    CustomerDataFields,
    CustomerCommunication,
    CustomerRelationList,
    CustomerHistory,
    ApplicationCorporateAnnualReportList,
    ApplicationPaidTaxesList
  },

  mixins: [isDepositEnabled, isFactoringEnabled],

  computed: {
    ...mapState('customers', [
      'customer',
      'customerLoans',
      'customerRelatedLoans',
      'customerComments',
      'customerDeposits',
      'customerFactoring',
      'customerThirdParties',
      'customerAnnualReports'
    ]),
    ...mapState('applications', ['applicationPaidTaxes']),
    ...mapGetters('customers', ['isCorporateCustomer']),
    ...mapGetters('classifiers', ['classifierById']),
    isNewFeature () {
      return process.env.VUE_APP_NEW_FEATURE
    },
    loanContracts () {
      const { active, delayed, terminated, breached, agreed, collector, bankrupt } = loanStatuses

      const activeStatuses = [active, delayed, terminated, breached, agreed, collector, bankrupt]

      return this.customerLoans.filter(
        ({ debts, status }) => activeStatuses.includes(status?.code) || debts
      )
    },

    depositContracts () {
      const { active, delayed } = loanStatuses

      const activeStatuses = [active, delayed]

      return this.customerDeposits.filter(
        ({ debts, status }) => activeStatuses.includes(status?.code) || debts
      )
    },

    factoringContracts () {
      const { active, delayed } = loanStatuses

      const activeStatuses = [active, delayed]

      return this.customerFactoring.filter(
        ({ debts, status }) => activeStatuses.includes(status?.code) || debts
      )
    },
    annualReports () {
      return plainToClass(CApplicationCorporateAnnualReport, this.customerAnnualReports)
    },
    relatedLoans () {
      return this.customerRelatedLoans.filter(loan => loan)
    },
    customerNotes () {
      return this.customerComments.filter(({ commentableType }) => /CUSTOMER/i.test(commentableType.code))
    }
  },

  async created () {
    const customerId = this.$route.params.id
    if (!customerId) {
      console.error('customerId is undefined')
      return
    }
    await this.loadComments({ filters: { active: true, hasAction: false } })
    if (this.isDepositEnabled) {
      await this.loadCustomerDeposits({ id: customerId })
    }
    await this.loadCustomerLoans({ id: customerId })
    await this.loadCustomerRelatedLoans({ customerId })
    await this.loadCustomerHistory({ customerId })
    if (this.isFactoringEnabled) {
      await this.loadCustomerFactoringContracts({ customerId })
      await this.loadCustomerFactoringThirdParties({ customerId })
    }
    if (this.isCorporateCustomer(this.customer)) await this.loadApplicationPaidTaxes({ idCode: this.customer.idCode, idCodeCountry: this.classifierById('countries', this.customer.countryId).code.toUpperCase() })
    await this.loadCustomerAnnualReports({ customerId })
    if (this.customer.corporateCustomer.emtakId) this.loadClassifiersEMTAK()
    if (this.customer.corporateCustomer.naceId) this.loadClassifiersNACE()
  },

  methods: {
    ...mapActions('applications', [
      'loadApplicationPaidTaxes'
    ]),
    ...mapActions('customers', [
      'loadCustomerRelatedLoans',
      'loadCustomerLoans',
      'loadCustomerDeposits',
      'loadCustomerHistory',
      'loadComments',
      'loadCustomerFactoringContracts',
      'loadCustomerFactoringThirdParties',
      'loadCustomerAnnualReports',
      'createCustomerAnnualReport',
      'editCustomerAnnualReport'
    ]),
    ...mapActions('classifiers', ['loadClassifiersEMTAK', 'loadClassifiersNACE']),
    async updateAnnualReport ({
      index = -1,
      report,
      done
    } = {}) {
      if (report) {
        report.corporateCustomerId = this.customer.id
      }
      // report.corporateCustomerId = this.applicationCustomer.id
      // const newReportList = [...this.customerAnnualReports ?? []]

      // newReportList.splice.apply(newReportList, [index, ~index && 1, report].filter(i => typeof i !== 'undefined'))
      if (report.id) {
        try {
          await this.editCustomerAnnualReport({
            reportId: report.id,
            ...report
          })
          // this.$refs.modal.hide()
          if (done) {
            done()
          }
        } catch (e) {
          this.error = e
        }
      } else {
        try {
          await this.createCustomerAnnualReport({
            customerId: this.$route.params.id,
            ...report
          })
          // this.$refs.modal.hide()
          if (done) {
            done()
          }
        } catch (e) {
          this.error = e
        }
      }

      // try {
      //   await this.updateApplication({
      //     id: this.application.id,
      //     applicationData: {
      //       annualReports: newReportList
      //     },
      //     type: UpdateTypes.CorporateAnnualReports
      //   })
      //   // eslint-disable-next-line no-unused-expressions
      //   done?.()
      // } catch {}
    }
  }
}
</script>


<i18n>
en:
  customerData:    "Customer data"
  activeLoans:     "Active loan contracts"
  activeDeposits:  "Active deposit contracts"
  activeFactoring: "Active factoring contracts"
et:
  customerData:    "Customer data"
  activeLoans:     "Active loan contracts"
  activeDeposits:  "Active deposit contracts"
  activeFactoring: "Active factoring contracts"
ru:
  customerData:    "Customer data"
  activeLoans:     "Active loan contracts"
  activeDeposits:  "Active deposit contracts"
  activeFactoring: "Active factoring contracts"
</i18n>
